import Box from "../Box";
import HomeCarouselCard from "../carousel-cards/HomeCarouselCard";
import Carousel from "../carousel/Carousel";
import Container from "../Container";
import Navbar from "../navbar/Navbar";
import React, { Fragment } from "react";
import { useHomeContext } from "../../contexts/app/HomeContext.tsx";
import styled, { css } from 'styled-components'
import StyledContainer from "./HomeSliderStyle";
import useWindowSize from "../../hooks/useWindowSize";
import { Span } from "../Typography";
import { Link } from "@inertiajs/react";
import { useAppContext } from "../../contexts/app/AppContext";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Button from "../buttons/Button";
import FlexBox from "../FlexBox";
import { useEffect, useLayoutEffect } from "react";
import { useTranslation } from 'react-i18next';

const HomeSlider: React.memo = ({ isMounted, setIsMounted }) => {

  const { sliders } = useHomeContext();
  const { state } = useAppContext()
  const { isAuth } = state.auth
  const width = useWindowSize();

  const { t, i18n } = useTranslation()


  useLayoutEffect(() => {
    const loadImage = imageData => {
      return new Promise((resolve, reject) => {
        const img = new window.Image()
        img.src = imageData.image_url
        img.onload = () => resolve(imageData.image_url)
        img.onerror = err => reject(err)
      })
    }

    Promise.all(sliders.map((imageData: any) => loadImage(imageData)))
      .then(() => setIsMounted(true))
      //.then(() => console.log('loaded'))
      .catch(err => console.log("Failed to load images", err))
  }, [])

  return (
    <Fragment>
      {/* <Navbar navListOpen={true} /> */}
      <Box bg="#212121" mb="3.75rem" mt={useWindowSize() < 576 && '20%'}>
        <StyledContainer>

          <Carousel
            totalSlides={sliders.length}
            visibleSlides={1}
            infinite={false}
            autoPlay={false}
            showDots={false}
            showArrow={true}
            spacing="0px"
            arrowButtonColor='white'
            leftButtonStyle={{ backgroundColor: 'transparent' }}
            rightButtonStyle={{ backgroundColor: 'transparent' }}
            leftButtonClass={{}}
          //rightButtonClass='right-arrow'
          >

            {sliders && sliders.map((item) => (
              <HomeCarouselCard title={item.title} key={item.id} content={item.content} imgPath={width <= 500 ? item?.mobile_image_url : item?.image_url} url_to={item.url} />
            ))}

          </Carousel>

          {useWindowSize() <= 768 && isMounted &&  <FlexBox pt={3} width='70%' margin='auto' alignItems='center' justifyContent='center'>
            <Link href={isAuth ? '' : '/customer/login'} className="btn"> <Button>{t('bulk')} <NavigateNextIcon /></Button></Link>
          </FlexBox>}



        </StyledContainer>
      </Box>
    </Fragment>
  );
};

export default HomeSlider;
