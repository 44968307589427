import styled, { css } from 'styled-components'
import Container from '../Container'

  const StyledContainer = styled(Container)`
  min-width : 100%;
  margin : 0;
  padding-bottom : 2rem;
  

  .arrow-container {
    position: absolute;
    bottom: 3rem;
    left : 0
    display: flex;
    width: 200px;
    height: 100px;
    flex-direction: row;
    justify-content: space-evenly;
    z-index:1;
  }

  .right-arrow{
   
  }

  @media only screen and (max-width: 900px) {
    .arrow-container{
      position : initial;
      height : 0px;
    }


  }

  @media only screen and (max-width : 486px ) {
    padding-bottom : 1rem;

  }
`

export default StyledContainer