import React from "react";
import Button from "../buttons/Button";
import Typography from "../Typography";
import { Small } from "../Typography";
import { StyledCarouselCard1 } from "./CarouselCardStyle";
import styled, { css } from 'styled-components'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from '@inertiajs/react';;

export interface HomeCarouselCardProps {
  title?: string;
  content?:string
}



const HomeCarouselCard: React.memo<HomeCarouselCardProps> = ({ title, content,imgPath, url_to=null}) => {

  return (
    <StyledCarouselCard1 >
      <div className="inner-description">
        {/*<h1 className="title">{title}</h1>*/}
        <Small className="testsmall" fontWeight='100' color="white" mb="1.35rem">
        {/*<div dangerouslySetInnerHTML={{ __html: content }} />*/}
        </Small>
        <Button
          className="button-link"
          variant="text"
          color="white"
          size='large'
          p="1rem 1.5rem"
          
        >
          {/*<Typography> SHOP NOW &gt; </Typography>*/}
        </Button>
      </div>

      <div className="image-holder" style={{width : '100%' , height : '100%'}}>
      <>

      { url_to   ?    
      <Link href={url_to}>
      <LazyLoadImage
      visibleByDefault={true}
      alt={title}
      height='100%'
      style={{width : '100%' , height : '100%'}}
      src={imgPath} // use normal <img> attributes as props
      width='100%' />
      </Link>
      : 
      <LazyLoadImage
      visibleByDefault={true}
      alt={title}
      height='100%'
      style={{width : '100%' , height : '100%'}}
      src={imgPath} // use normal <img> attributes as props
      width='100%' />

    
      }</>
      
      </div>
    </StyledCarouselCard1>
  );
};

export default HomeCarouselCard;
