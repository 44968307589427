import styled from "styled-components";

export const StyledCarouselCard1 = styled.div`
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 1rem 2rem;
  min-width : 100%;
  padding-bottom : 2rem;
  padding : 0;
  height : 51vw;

  img{
    object-fit : cover;
  }

  .title {
    font-size: 70px;
    margin-top: 0px;
    margin-bottom: 1.35rem;
    line-height: 1.2;
    color : white;
  }

  .inner-description{
    position:absolute;
    z-index : 1;
    left : 10.5%;
    width : 464px;
  }

  .button-link{
    float : right;
    font-size : 22px;
  }

  .image-holder {
    position: relative;
    //   width: 50%;
    img {
      width: 100%;
    }
  }

  @media only screen and (max-width: 1200px) {
    height : 60vw;
    img{
      object-fit : contain;
    }
  }



  @media only screen and (max-width: 900px) {
    margin-left: 0px;
    padding-left: 0px;
    

    .title {
      font-size: 32px;
    }
  }

  @media only screen and (max-width: 576px) {
    /min-height : 523px;
    

    .inner-description{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      left:0
    }

    .title {
      font-size : 35px;
    }

    button {
      font-size : 12px;
    }

    .inner-description > span {
      display: none;
    }
  }

  @media only screen and (max-width : 500px){

    height : 100%;

  }

  @media only screen and (max-width: 435px) {
    
    .button-link {
      padding: 0.66rem 0.95rem;
      font-size : 12px;
    }

    
  }
`;
